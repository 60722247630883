.block-title.block-title-evento {
  text-align: center;
  color: #000;
  font-weight: normal;
  font-size: 1rem; }
  .block-title.block-title-evento span {
    font-weight: bolder;
    color: var(--f7-theme-color);
    font-size: 1.2rem; }

.page-content.login-screen-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center; }

img#logo {
  max-width: 40vw; }

small {
  font-size: 0.9rem; }

#preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*border: solid 1px red;
	background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
	background-size: 80px 80px;
	background-position: 0 0, 0 40px, 40px -40px, -40px 0px;*/
  background: #000; }

.toolbar-inner > .row {
  width: 100%; }

.Modal-Portal .datepicker-modal {
  z-index: 5001; }

.datepicker.ios .datepicker-navbar-btn {
  color: var(--f7-theme-color);
  font-weight: bold;
  padding: 0 2rem;
  background: rgba(127, 127, 127, 0.1); }

.label-radio {
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end; }
  .label-radio:last-child {
    margin-right: 0; }
  .label-radio span {
    font-size: 16px;
    font-weight: 400;
    color: var(--f7-label-text-color);
    line-height: 1;
    margin-left: 0.5rem; }

.item-after-link .item-after span {
  text-decoration: underline;
  color: var(--f7-text-color);
  font-size: 12px; }

#canvasContainer {
  display: block;
  border: solid 1px var(--f7-label-text-color); }

canvas {
  width: 100%;
  min-height: 200px; }

#logo {
  max-height: 70px;
  text-align: center;
  margin: auto auto;
  display: block;
  padding-top: 1rem; }

.unstyled {
  -webkit-appearance: none !important; }

.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-clear-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none !important;
  -webkit-appearance: none !important; }

label.radio > .item-input-error-message {
  display: none; }

.data-di-nascita .sep {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #999999; }

.data-di-nascita input[type='number'] {
  -moz-appearance: textfield; }

.data-di-nascita input::-webkit-outer-spin-button,
.data-di-nascita input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

/* Material Icons Font (for MD theme) */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIcons-Regular.e79bfd88.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/static/media/MaterialIcons-Regular.570eb838.woff2) format('woff2'),
       url(/static/media/MaterialIcons-Regular.012cf6a1.woff) format('woff'),
       url(/static/media/MaterialIcons-Regular.a37b0c01.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* Framework7 Icons Font (for iOS theme) */
@font-face {
  font-family: 'Framework7 Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Framework7Icons-Regular.589f22db.eot);
  src: url(/static/media/Framework7Icons-Regular.988e8c7a.woff2) format("woff2"),
       url(/static/media/Framework7Icons-Regular.0efb066c.woff) format("woff"),
       url(/static/media/Framework7Icons-Regular.48c1c710.ttf) format("truetype");
}

.f7-icons {
  font-family: 'Framework7 Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  text-align: center;
}

:root {
  --f7-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", Arial, sans-serif;
  --f7-button-padding-horizontal: 2rem;
  --f7-theme-color: #4e3629;
  --f7-bars-text-color: #4e3629;
  --f7-bars-bg-color: #f0ece4;
  --f7-page-bg-color: #fafafa; }

/*
$native-family-base:
$native-family-monospace: Consolas, 'Liberation Mono', Menlo, Monaco, "Courier New", Courier, monospace;
$generic-family-sans: "Helvetica Neue", Helvetica, Arial, sans-serif;
$generic-family-serif: 'PT Serif', Georgia, Cambria, "Times New Roman", Times, serif;
$generic-family-headers: 'Source Sans Pro', "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Arial, sans-serif;
 */
.m-top {
  margin-top: 1rem !important; }

.m-top2 {
  margin-top: 2rem !important; }

.p-top {
  padding-top: 1rem !important; }

.p-top2 {
  padding-top: 2rem !important; }

.toast.success-toast {
  background: #4cd964;
  --f7-toast-bg-color: rgba(#4cd964, 0.75);
  --f7-toast-translucent-bg-color-ios: rgba(#4cd964, 0.75); }

.toast.alert-toast {
  background: #ff3b30;
  --f7-toast-bg-color: rgba(#ff3b30, 0.75);
  --f7-toast-translucent-bg-color-ios: rgba(#ff3b30, 0.75); }
  .toast.alert-toast.toast-with-icon .toast-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .toast.alert-toast.toast-with-icon .toast-content .toast-text {
      flex-grow: 1;
      font-weight: bold; }

.flex-grow {
  flex-grow: 1; }

.dialog-preloader .dialog-title {
  --f7-dialog-title-font-weight: normal; }

.button > .icon {
  margin-right: .5rem; }

.hide-item-after > a > .item-content > .item-inner > .item-after {
  visibility: hidden; }

.flex-row-vcenter {
  display: flex;
  flex-direction: row;
  align-items: center; }

.flex-row-vcenter-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.simple-list .no-border li:after, .links-list .no-border a:after, .list .no-border .item-inner:after {
  background: transparent; }

.wauto {
  width: auto; }

.maxw300 {
  max-width: 300px; }

.m-auto {
  margin-left: auto;
  margin-right: auto; }

.m-bot {
  margin-bottom: 1rem !important; }

.m-bot2 {
  margin-bottom: 2rem !important; }

