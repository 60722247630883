
.page-content.login-screen-content {
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: center;
}

img#logo {
	max-width: 40vw;
}

small {
	font-size: 0.9rem;
}
