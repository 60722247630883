
.block-title.block-title-evento {
	text-align: center;
	color: #000;
	font-weight: normal;
	font-size: 1rem;

	span {
		font-weight: bolder;
		color: var(--f7-theme-color);
		font-size: 1.2rem;
	}

}
