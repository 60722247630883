
#preview {

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	/*border: solid 1px red;
	background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
	background-size: 80px 80px;
	background-position: 0 0, 0 40px, 40px -40px, -40px 0px;*/

	background: #000;

}

.toolbar-inner > .row {
	width: 100%;
}
