// FIX FOR DATEPICKER
.Modal-Portal {
	.datepicker-modal {
		z-index: 5001;
	}
}

.datepicker.ios .datepicker-navbar-btn {
	color: var(--f7-theme-color);
	font-weight: bold;
	padding: 0 2rem;
	background: rgba(127, 127, 127, .1);
}

.label-radio {

	margin-right: 1rem;

	&:last-child {
		margin-right: 0;
	}

	span {
		//font-size: small;
		font-size: 16px;
		font-weight: 400;
		//line-height: var(--f7-label-line-height);
		color: var(--f7-label-text-color);

		line-height: 1; //var(--f7-radio-size);
		margin-left: 0.5rem;
	}

	display: flex;
	flex-direction: row;
	align-items: flex-end;

}


.item-after-link .item-after span {
	text-decoration: underline;
	color: var(--f7-text-color);
	font-size: 12px;
}

#canvasContainer {
	display: block;
	border: solid 1px var(--f7-label-text-color);
}

canvas {
	width: 100%;
	min-height: 200px;
}

#logo {
	max-height: 70px;
	text-align:center;
	margin: auto auto;
	display: block;
	padding-top: 1rem;
}


.unstyled {
	-webkit-appearance: none !important;
}
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-clear-button,
.unstyled::-webkit-calendar-picker-indicator {
	display: none !important;
	-webkit-appearance: none !important;
}
