// @flow strict

.data-di-nascita {
	.sep {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		color: #999999;
	}

	input[type='number'] {
		-moz-appearance:textfield;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

}
