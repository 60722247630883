
:root {
	--f7-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", Arial, sans-serif;
	--f7-button-padding-horizontal: 2rem;

	--f7-theme-color: #4e3629;
	--f7-bars-text-color: #4e3629;
	--f7-bars-bg-color: #f0ece4;
	//--f7-statusbar-bg-color: var(--f7-bars-bg-color);
	--f7-page-bg-color: #fafafa;
}

/*
$native-family-base:
$native-family-monospace: Consolas, 'Liberation Mono', Menlo, Monaco, "Courier New", Courier, monospace;
$generic-family-sans: "Helvetica Neue", Helvetica, Arial, sans-serif;
$generic-family-serif: 'PT Serif', Georgia, Cambria, "Times New Roman", Times, serif;
$generic-family-headers: 'Source Sans Pro', "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Arial, sans-serif;
 */


.m-top {
	margin-top: 1rem !important;
}

.m-top2 {
	margin-top: 2rem !important;
}

.p-top {
	padding-top: 1rem !important;
}

.p-top2 {
	padding-top: 2rem !important;
}


.toast.success-toast {
	background: #4cd964;
	--f7-toast-bg-color: rgba(#4cd964, 0.75);
	--f7-toast-translucent-bg-color-ios: rgba(#4cd964, 0.75);
}


.toast.alert-toast {
	background: #ff3b30;
	--f7-toast-bg-color: rgba(#ff3b30, 0.75);
	--f7-toast-translucent-bg-color-ios: rgba(#ff3b30, 0.75);

	&.toast-with-icon {

		.toast-content {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			.toast-text {
				flex-grow: 1;
				font-weight: bold;
			}

		}
	}

}


.flex-grow {
	flex-grow: 1;
}


.dialog-preloader {

	.dialog-title {
		--f7-dialog-title-font-weight: normal;
	}

}


.button > .icon {
	margin-right: .5rem;
}


// Hide element
// TODO
.hide-item-after > a > .item-content > .item-inner > .item-after {
	visibility: hidden;
}


.flex-row-vcenter {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.flex-row-vcenter-center {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}


.simple-list .no-border li:after, .links-list .no-border a:after, .list .no-border .item-inner:after {
	background: transparent;
}

.wauto {
	width: auto;
}

.maxw300 {
	max-width: 300px;
}

.m-auto {
	margin-left: auto;
	margin-right: auto;
}

.m-bot {
	margin-bottom: 1rem !important;
}

.m-bot2 {
	margin-bottom: 2rem !important;
}
